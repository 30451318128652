import React, {useEffect} from 'react';
import RestaurantName from './RestaurantName/RestaurantName';
import MenuCategory from './MenuCategory/MenuCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useMenuApi from '../../hooks/useMenuApi';
import { Currency } from './iMenuData';

interface MenuProps {
     restaurantId ?: string
}
const Menu: React.SFC<MenuProps> = (props) => {

     const [{data, isError, isLoading}] = useMenuApi(props.restaurantId);
     
     useEffect(() => {
          if(data) document.title = `${process.env.REACT_APP_SITE_TITLE} - ${data.restaurantName}`;
     }, [data]);

     useEffect(() => { document.title = process.env.REACT_APP_SITE_TITLE || ""},[]);

     return (
          <>
          { isError && <div>Si è verificato un errore</div> }
          { isLoading && !isError ? <div><FontAwesomeIcon icon={faSpinner} spin/> Caricamento Menu ...</div>  : 
               typeof data == "undefined" ? "" :  
               <>
                    <RestaurantName name={data.restaurantName} />
                    <h2>Menu</h2>
                    { data.menu.categories.map(category => (
                         <MenuCategory 
                              key={category.id}
                              name={category.name} 
                              plus={category.plus} 
                              currency={Currency[data.currency]}
                         />
                    )) }
               </>
               
          }
          </>
     );
}


export default Menu;