import React from 'react';

import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import MenuView from '../views/menuView/MenuView';
import HomeView from '../views/homeView/HomeView';
import NotFoundView from '../views/notFoundView/NotFoundView';


const Routes: React.SFC = (props) => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={HomeView}></Route>
                <Route exact path="/menu/:id" component={MenuView}></Route>
                <Route component={NotFoundView} />
            </Switch>                    
        </Router>
    );
}

export default Routes;