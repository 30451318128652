import { IMenuData } from "../components/Menu/iMenuData";
import { useState, useEffect } from "react";
import axios from 'axios';

const url = process.env.REACT_APP_API_4MENU_HOST + process.env.REACT_APP_API_GET_MENU !;

const useMenuApi = (restaurantId? : string) => {
     const [data, setData] = useState<IMenuData>();
     const [isLoading, setIsLoading] = useState<boolean>(true);
     const [isError, setIsError] = useState<boolean>(false);  

     
     useEffect(() => {
          
          const getMenuData = async() => {
               setIsError(false);
               setIsLoading(true);

               try {
                    const result = await axios.get<{data: IMenuData}>(`${url}/${restaurantId}`);
                    setData(result.data.data);
                    setIsLoading(false);

               }
               catch(e) {

                    setIsError(true);
                    
               }
          }
          getMenuData();
     }, [restaurantId]);

     return [{ data, isError, isLoading}];
}

export default useMenuApi;