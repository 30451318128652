import React from 'react';

const HomeView: React.SFC = () => {
     return (
          <>
               <h1 style={{textAlign: 'center'}}>Welcome to Menu View App!</h1>
          </>
     );
}

export default HomeView;