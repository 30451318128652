import React, {useState} from 'react';
import { IPlu } from '../iMenuData';
import MenuItem from '../MenuItem/MenuItem';
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MenuCategoryProps {
     name: string,
     plus: IPlu[],
     currency: string
}

const MenuCategory: React.SFC<MenuCategoryProps> = (props) => {

     const [isCategoryCollapsed, setIsCategoryCollapsed] = useState<boolean>(false);

     const toggleCategory = (e: any) => {
          setIsCategoryCollapsed(!isCategoryCollapsed);
     }
     
     return (
          <>
               <div onClick={e => toggleCategory(e)} style={{cursor:"pointer"}}>
                    <h2>{props.name} {isCategoryCollapsed ? <FontAwesomeIcon icon={faChevronRight}/> : <FontAwesomeIcon icon={faChevronDown}/> }</h2>
               </div>
               <table style={{width: "100%", maxWidth: "800px", borderCollapse: "collapse"}}>
                    <tbody>
                         { isCategoryCollapsed ? "" : 
                              props.plus.map(plu => (
                                   <MenuItem key={plu.id} id={plu.id} name={plu.name} price={plu.price} description={plu.description || ""} currency={props.currency} />
                         ))}
                    </tbody>
               </table>
          </>
     )
}

export default MenuCategory;