import React from 'react';

const NotFoundView: React.SFC = () => {
     return (
          <>
               <h1 style={{textAlign: 'center'}}>404</h1>
          </>
     );
}

export default NotFoundView;