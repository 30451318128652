import React from 'react';

interface MenuItemProps {
     id : number,
     name : string,
     price: string,
     description?: string,
     currency: string
}

const MenuItem: React.SFC<MenuItemProps> = (props) => {
     return (
          <>
               <tr>
                    <td style={{width:"80%"}}>{props.name}</td>
                    <td>{props.currency} {props.price}</td>
               </tr>
               <tr style={{borderBottom: "1px solid #d37a6d"}}>
                    <td colSpan={2} style={{fontSize: "0.85em", color: "darkgray"}}><i>{props.description}</i></td>
               </tr>
          </>
     );
}

export default MenuItem;