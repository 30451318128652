import React from 'react';

interface RestaurantNameProps {
     name : string
}

const RestaurantName: React.SFC<RestaurantNameProps> = (props) => {

     return <>
          <h1>{props.name}</h1>
     </>;
}

export default RestaurantName;