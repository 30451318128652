
export enum Currency {
     "EUR" = "€",
     "GBP" = "£",
     "USD" ="$"
}
export interface IPlu {
     id: number;
     name: string;
     description ?: string,
     price: string;
}
 
export interface ICategory {
     id: number;
     icon: string;
     name: string;
     plus: IPlu[];
}
 
export interface IMenu {
     categories: ICategory[];
}
 
export interface IMenuData {
     restaurantName: string;
     currency: keyof typeof Currency;
     menu: IMenu;
}