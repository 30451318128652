import React from 'react';
import Menu from '../../components/Menu/Menu';
import { RouteComponentProps } from 'react-router';

const MenuView: React.SFC<RouteComponentProps<{id ?: string}>> = (props) => 
{
     return (
          <>
               <Menu restaurantId={props.match.params.id} />
          </>
     );
}

export default MenuView;